import banner from './resources/images/banner.png';
import ad1 from './resources/images/1.png';
import ad2 from './resources/images/2.png';
import ad3 from './resources/images/3.png';
import './App.css';
import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';

import { services } from './services/services';
import { utils } from './utils/utils';
import moment from 'moment';
import { PeriodType } from './mytype/period';

type LottoAnnonceType = {
  up6?: string,
  up5?: string,
  up4?: string,
  up3?: string,
  up2?: string,
  down2?: string
}

type LottoByOneType = {
  number1?: string,
  number2?: string,
  number3?: string,
  number4?: string,
  number5?: string
}

type AnnounceType = {
  currentPeriod?: PeriodType | null,
  previousPeriod?: PeriodType | null,
  periods?: PeriodType[],
  numberAnnounce?: LottoByOneType,
  duration?: number,
  showDate?: Date | string | moment.Moment,
  stopAnnounceDate: Date | string | moment.Moment,
  condition?: number,
  endpoint?: string,
  todayDate?: Date | string | moment.Moment,
  announceStartDate: Date | string | moment.Moment,
  announceEndDate: Date | string | moment.Moment,
  oldAnnounce: boolean,
  newAnnounce: boolean,
  beforeStartUp: boolean,
  oldUpAnnounce: boolean,
  upAnnounce: boolean
}

const beforeStartUpAnnounceDate = moment().format('YYYY-MM-DD 11:50:00');
const afterStartUpAnnounceDate = moment().format('YYYY-MM-DD 11:54:59');
const startUpAnnounceDate = moment().format('YYYY-MM-DD 11:55:00');
const endUpAnnouneDate = moment().format('YYYY-MM-DD 11:59:59');
const upAnnounceDate = moment().format('YYYY-MM-DD 12:00:00');

const getLoading = () => {
  return 'loading';
}

const imageLoading = () => {
  return <img
    style={{ marginRight: 2 }}
    src={require('./resources/images/loading.gif')}
    width={50}
    height={50}
    alt='number'
  />;
}

class App extends Component {

  animationTimer: any = null;
  upTimer: any = null;
  _isMounted: boolean = false;

  state: Readonly<AnnounceType> = {
    currentPeriod: null,
    previousPeriod: null,
    periods: [],
    showDate: moment(),
    numberAnnounce: {
      number1: getLoading(),
      number2: getLoading(),
      number3: getLoading(),
      number4: getLoading(),
      number5: getLoading()
    },
    duration: (1000 * 60) * 1,
    stopAnnounceDate: new Date(),
    todayDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    condition: 3,
    endpoint: process.env.REACT_APP_API_URL,
    announceStartDate: moment().format('YYYY-MM-DD 11:50:00'),
    announceEndDate: moment().format('YYYY-MM-DD 12:00:00'),
    beforeStartUp: false,
    oldAnnounce: false,
    newAnnounce: false,
    oldUpAnnounce: false,
    upAnnounce: false,
  };

  componentWillUnmount(): void {
    this._isMounted = false;
    clearInterval(this.animationTimer);
    clearInterval(this.upTimer);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getAllStatistics();
    this.getPeriodsShow();
    this.getCurrentPeriod();

    this.setTimer();
  }

  getAllStatistics = async () => {
    const response = await services.getStatistics();
    this.setState({
      periods: response.data
    })
  }

  setTimer = () => {
    if (!this.animationTimer) {
      this.animationTimer = setInterval(() => {
        this.setState({ todayDate: moment().format('YYYY-MM-DD HH:mm:ss') });
        this.announce();
      }, 1000);
    }
  }

  announce = () => {
    const { announceStartDate, announceEndDate, todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() < moment(announceStartDate).toDate().getTime()) {
      this.beforeAnnonce();
    } else if (
      moment(todayDate).toDate().getTime() >= moment(announceStartDate).toDate().getTime()
      && moment(todayDate).toDate().getTime() <= moment(announceEndDate).toDate().getTime()
    ) {
      this.setData();
    } else {
      this.afterAnnounce();
    }
  }

  beforeAnnonce = async () => {
    const { todayDate, oldAnnounce } = this.state;
    if (!oldAnnounce) {
      const dateNow = moment(todayDate).add(-1, 'd').format("YYYY-MM-DD 12:00:00");
      const period = await services.getPeriods(dateNow, 'both', '3');
      const up = period.data.up6;

      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4]
        },
        oldAnnounce: true
      })
    }
  }

  afterAnnounce = async () => {
    const { newAnnounce } = this.state;
    if (!newAnnounce) {
      const statisticToday = await services.getStatisticToday();

      const up = statisticToday.data.up6;

      this.setState({
        numberAnnounce: {
          number1: up[0],
          number2: up[1],
          number3: up[2],
          number4: up[3],
          number5: up[4]
        },
        newAnnounce: true
      })
    }

  }

  setData = async () => {
    const { todayDate, oldUpAnnounce, upAnnounce, beforeStartUp } = this.state;

    // --------------------- *** UP *** -------------------------
    if (moment(todayDate).toDate() < moment(startUpAnnounceDate).toDate()) {
      // show old up
      if (!oldUpAnnounce) {

        this.setState({
          numberAnnounce: {
            number1: getLoading(),
            number2: getLoading(),
            number3: getLoading(),
            number4: getLoading(),
            number5: getLoading()
          },
          oldUpAnnounce: true
        });
      }

    }

    // before start up
    if (moment(todayDate).toDate() >= moment(beforeStartUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(afterStartUpAnnounceDate).toDate()) {
      if (!beforeStartUp) {
        this.setState({
          numberAnnounce: {
            number1: getLoading(),
            number2: getLoading(),
            number3: getLoading(),
            number4: getLoading(),
            number5: getLoading()
          },
          beforeStartUp: true
        })
      }
    }

    // up random number started
    if (moment(todayDate).toDate() >= moment(startUpAnnounceDate).toDate() && moment(todayDate).toDate() <= moment(endUpAnnouneDate).toDate()) {
      if (!this.upTimer) {
        this.upTimer = setInterval(() => {
          this.setState({
            numberAnnounce: {
              number1: Math.floor(Math.random() * 10),
              number2: Math.floor(Math.random() * 10),
              number3: Math.floor(Math.random() * 10),
              number4: Math.floor(Math.random() * 10),
              number5: Math.floor(Math.random() * 10)
            },
          })
        }, 100);
      }
    }

    // up announce
    if (moment(todayDate).toDate().getTime() === moment(upAnnounceDate).toDate().getTime()) {
      if (this.upTimer) clearInterval(this.upTimer);

      if (!upAnnounce) {
        // call api and show up 
        const statisticToday = await services.getStatisticToday();
        await this.getCurrentPeriod();
        const up = statisticToday.data.up6;

        this.setState({
          numberAnnounce: {
            number1: up[0],
            number2: up[1],
            number3: up[2],
            number4: up[3],
            number5: up[4]
          },
          upAnnounce: true
        })
      }
    }
  }

  getCurrentPeriod = async () => {
    let { currentPeriod } = this.state;
    const response = await services.getCurrentPeriods();
    currentPeriod = response.data;
    this.setState({ currentPeriod });
  }

  getPeriodsShow = async () => {
    let { periods, previousPeriod } = this.state;
    const response = await services.getPeriodsShow();
    periods = response.data;
    if (periods) {
      previousPeriod = periods[0];
      this.setState({ periods, previousPeriod });
    }
  }

  getConditionAnnounce = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
    const day = ("0" + currentDate.getDate()).slice(-2);

    let { condition, showDate, stopAnnounceDate } = this.state;
    stopAnnounceDate = moment(year + "-" + month + "-" + day + ' 12:00:00').toDate();

    if (
      currentDate >= moment(year + "-" + month + "-" + day + ' 11:50:00').toDate()
      && currentDate <= moment(year + "-" + month + "-" + day + ' 11:54:59').toDate()
    ) {
      // ออกผล
      showDate = currentDate;
      condition = 1;
    } else if (
      currentDate >= moment(year + "-" + month + "-" + day + ' 11:55:00').toDate()
      && currentDate <= moment(year + "-" + month + "-" + day + ' 11:59:59').toDate()
    ) {
      // เตรียมออกผล
      showDate = currentDate;
      condition = 2;
    }
    else {
      if (currentDate < moment(year + "-" + month + "-" + day + ' 12:00:00').toDate()) {
        // เอางวดก่อนมาแสดง
        showDate = moment().subtract(1, 'days');
        condition = 3;
      } else {
        // เอางวดใหม่แสดงแต่ไม่หมุน
        showDate = currentDate;
        condition = 4;
      }
    }

    return {
      condition,
      showDate,
      stopAnnounceDate
    }

    // this.setState({ condition, showDate, stopAnnounceDate });
  }

  renderDateTime() {

    const { todayDate } = this.state;

    if (moment(todayDate).toDate().getTime() >= moment(beforeStartUpAnnounceDate).toDate().getTime()) {
      return 'ງວດປະຈຳວັນທີ່ ' + utils.convertToThaiDate(moment().toString());
    }

    return 'ງວດປະຈຳວັນທີ່ ' + utils.convertToThaiDate(moment().add(-1, 'd').toString());
  }

  renderAnnounceTable() {
    const { condition } = this.getConditionAnnounce();

    let nums: LottoAnnonceType = {
      up6: '-',
      up5: '-',
      up4: '-',
      up3: '-',
      up2: '-',
      down2: '-'
    };

    if (condition === 3) {
      const { previousPeriod } = this.state;
      if (!previousPeriod) {
        return (
          <tbody>
            <tr className="text-center">
              <td colSpan={2}>
                <Spinner animation="border" role="status" />
              </td>
            </tr>
          </tbody>
        );
      }
      nums.up6 = previousPeriod?.up6;
      nums.up5 = previousPeriod.up6;
      nums.up4 = previousPeriod.up6?.substring(1, previousPeriod.up6?.length);
      nums.up3 = previousPeriod?.up3;
      nums.up2 = previousPeriod?.up2;
      nums.down2 = previousPeriod?.down2;
    } else if (condition === 4) {
      const { currentPeriod } = this.state;
      if (!currentPeriod) {
        return (
          <tbody>
            <tr className="text-center">
              <td colSpan={2}>
                <Spinner animation="border" role="status" />
              </td>
            </tr>
          </tbody>
        );
      }
      nums.up6 = currentPeriod?.up6;
      nums.up5 = currentPeriod.up6;
      nums.up4 = currentPeriod.up6?.substring(1, currentPeriod.up6?.length);
      nums.up3 = currentPeriod?.up3;
      nums.up2 = currentPeriod?.up2;
      nums.down2 = currentPeriod?.down2;
    }

    return (
      <tbody>
        <tr>
          <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 5 ໂຕ</td>
          <td style={{ width: '50%', textAlign: 'center' }}>{nums.up5}</td>
        </tr>
        <tr>
          <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 4 ໂຕ</td>
          <td style={{ width: '50%', textAlign: 'center' }}>{nums.up4}</td>
        </tr>
        <tr>
          <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 3 ໂຕ</td>
          <td style={{ width: '50%', textAlign: 'center' }}>{nums.up3}</td>
        </tr>
        <tr>
          <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 2 ໂຕເທິງ</td>
          <td style={{ width: '50%', textAlign: 'center' }}>{nums.up2}</td>
        </tr>
        <tr>
          <td style={{ width: '50%', textAlign: 'center' }}>ເລກ 2 ໂຕລຸ່ມ</td>
          <td style={{ width: '50%', textAlign: 'center' }}>{nums.down2}</td>
        </tr>
      </tbody>
    )
  }

  renderPeriods() {
    const { periods } = this.state;

    if (!periods) {
      return (
        <tr className="text-center">
          <td colSpan={7}>ไม่มีข้อมูล</td>
        </tr>
      );
    }

    if (periods.length === 0) {
      return (
        <tr className="text-center">
          <td colSpan={7}>ไม่มีข้อมูล</td>
        </tr>
      );
    }

    return periods.map((period, index) => {
      return (
        <tr key={index} className="text-center">
          <td>{utils.convertToThaiDate(period?.dateAnnounce.toString())}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up6}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up6?.substring(1, period.up6?.length)}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up3}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.up2}</td>
          <td style={{ verticalAlign: 'middle' }}>{period.down2}</td>
        </tr>
      );
    });
  }

  render(): React.ReactNode {

    const { numberAnnounce } = this.state;

    return (
      <div className="container p-0">
        <div className="header d-flex justify-content-center">
          <img src={banner} className="img-fluid" height="250px" alt="ຫວຍ ຫວຍລາວ" />
        </div>
        <div className="row pt-1">
          <div className="col-md-8 col-sm-12 col-xs-12">
            <div className="card" style={{ border: 'none' }}>
              <div className="card-body text-center">
                <div>
                  <h5><b id="period-date-time">{this.renderDateTime()}</b></h5>
                  <h1 style={{ fontSize: '4rem', fontWeight: 700, letterSpacing: 8 }}>

                    <span id="n1">{numberAnnounce?.number1 !== 'loading' ? numberAnnounce?.number1 : imageLoading()}</span>
                    <span id="n2">{numberAnnounce?.number2 !== 'loading' ? numberAnnounce?.number2 : imageLoading()}</span>
                    <span id="n3">{numberAnnounce?.number3 !== 'loading' ? numberAnnounce?.number3 : imageLoading()}</span>
                    <span id="n4">{numberAnnounce?.number4 !== 'loading' ? numberAnnounce?.number4 : imageLoading()}</span>
                    <span id="n5">{numberAnnounce?.number5 !== 'loading' ? numberAnnounce?.number5 : imageLoading()}</span>
                  </h1>
                  <p id="odometer"></p>
                  <h5><b>ເວລາອອກເລກ 12:00</b></h5>
                </div>
              </div>
            </div>
            <table className="table table-bordered" style={{ marginTop: 5, marginBottom: '0px' }}>
              {this.renderAnnounceTable()}
            </table>
            <div className="alert alert-secondary text-center" style={{ margin: '10px 0 0 0', backgroundColor: '#b8860b' }} role="alert">
              <h5 style={{ margin: 0, color: 'white' }}><b>ຜົນການອອກເລກລາງວັນ</b></h5>
            </div>

            <table className="table table-bordered period" style={{ marginBottom: '5px', fontSize: '13px' }}
              id="period-table">
              <thead>
                <tr className="text-center">
                  <th style={{ verticalAlign: 'middle' }}>ງວດວັນທີ່</th>
                  <th>ເລກ 5 ໂຕ</th>
                  <th>ເລກ 4 ໂຕ</th>
                  <th>ເລກ 3 ໂຕ</th>
                  <th>ເລກ 2 ໂຕເທິງ</th>
                  <th>ເລກ 2 ໂຕລຸ່ມ</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {this.renderPeriods()}
              </tbody>
            </table>
          </div>
          <div className="col-md-4 col-sm-12 col-xs-12 ">
            <div className="card d-flex justify-content-center mt-0">
              <div className="card-body p-0">
                <img src={ad1} width="100%" height="178px" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad2} width="100%" height="212px" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
            <div className="card d-flex justify-content-center mt-2">
              <div className="card-body p-0">
                <img src={ad3} width="100%" height="292px" alt="หวย หวยลาว ลาว ลาวเฉพาะกิจ ຫວຍ ຫວຍລາວ ຫວຍລາວສະເພາະກິດ" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App;